<template>
  <v-navigation-drawer color="#121212" permanent>

    <router-link class="text-no-decoration" :to="{ name: 'page-home' }">
      <v-img class="mx-auto mt-5" :src="$store.getters.getLogoUrl" max-width="80%" contain alt="Explore Top & New K-Drama OSTs | Free Streaming of Memorable Soundtracks on OSTFly">
      </v-img>
    </router-link>

    <v-list density="compact" nav>
      <v-list-item prepend-icon="mdi-home" title="Home" :to="{ name: 'page-home' }" value="home"
        class="text-white text-capitalize"></v-list-item>
      <v-list-item prepend-icon="mdi-magnify" title="Search" :to="{ name: 'page-search' }" value="search"
        class="text-white text-capitalize"></v-list-item>
      <v-list-item prepend-icon="mdi-play-box-multiple" title="Explore" :to="{ name: 'page-explore' }" value="explore"
        class="text-white text-capitalize"></v-list-item>
      <v-list-item prepend-icon="mdi-bookmark" title="Your library" :to="{ name: 'page-library' }" value="your library"
        class="text-white text-capitalize"></v-list-item>
      <!-- <v-list-item prepend-icon="mdi-account" title="Your Account" value="your account" class="text-white text-capitalize"></v-list-item> -->
      <!-- <v-list-item prepend-icon="mdi-logout" title="Logout" value="Logout" class="text-white text-capitalize"></v-list-item> -->
    </v-list>

    <v-row :class="['mx-2', { 'desktop-navs': player.visible, 'desktop-navs-inpl': !player.visible }]">
      <a :href="settings.telegram_chanel_url" target="_blank" class="w-100 mb-8">
        <v-img alt="Stay Updated with K-Drama OSTs on Telegram - Join Now for New K-Drama Music and Releases" :class="['mx-1 w-100']" src="@/assets/telegram_banner_pink_shadow.png"></v-img>
      </a>

      <router-link :to="{ name: 'page-about' }" :class="['mx-1 text-no-decoration text-white']">About Us</router-link>
      <router-link :to="{ name: 'page-terms' }" :class="['mx-1 text-no-decoration text-white']">Terms
        Services</router-link>
      <router-link :to="{ name: 'page-policy' }" :class="['mx-1 text-no-decoration text-white']">Privacy &
        Policy</router-link>


    </v-row>

  </v-navigation-drawer>
</template>

<script>


export default {

  computed: {
    player() {
      return this.$store.getters.getPlayerProps;
    },

    settings(){
      return this.$store.getters.settings;
    }

  },

  methods: {
    setCurrentTrack(track) {
      this.$store.commit('setCurrentTrack', track);
    },

    setCurrentTrackAsync(track) {
      this.$store.dispatch('setCurrentTrackAsync', track);
    }
  }
};

</script>

<style>
.desktop-navs {
  position: absolute;
  bottom: 120px;
  font-size: 10px;
}

.desktop-navs-inpl {
  position: absolute;
  bottom: 25px;
  font-size: 10px;
}

.banner-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

.banner-shadow:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}
</style>
