import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './routes'
import "@/assets/css/styles.css";
import vue3GoogleLogin from 'vue3-google-login'
import { VueReCaptcha } from 'vue-recaptcha-v3'

// import './registerServiceWorker'

// Create a global head instance

const app=createApp(App)
app.use(router)
app.use(vuetify)
app.use(store)
app.use(vue3GoogleLogin, {
    clientId: store.getters.getGoogleAuthClientId
})
app.use(VueReCaptcha,{
    siteKey: store.getters.getGoogleRecaptcha3ClientId
})
app.mount('#app')
