<template>

  <v-app class="rounded rounded-md bg-main">

    <!-- TOP APP BAR -->
    <v-app-bar v-if="isMobile" class="text-white" color="#121212" height="65">

      <v-img @click="go('page-home')" class="ml-4 mx-2 rounded-lg row-pointer" :src="$store.getters.getLogoUrl"
        max-height="42" max-width="42" contain alt="Explore Top & New K-Drama OSTs | Free Streaming of Memorable Soundtracks on OSTFly"></v-img>

      <span @click="go('page-home')" class="text-caption font-weight-bold row-pointer">OSTFly</span>

      <v-spacer></v-spacer>

      <!-- ABOUT MENU -->
      <!-- <v-btn icon> -->
        <div class="d-flex justify-end mr-4" v-if="isMobile">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon class="row-pointer" v-bind="props" color="white" icon="mdi-dots-vertical"
                title="menu"></v-icon>
            </template>

            <v-list style="background-color: #0A0A0A ; width: 220px;" class="text-white">
              <v-list-item class="row-pointer" :to="{ name: 'page-terms' }">
                <v-icon class="mx-1" icon="mdi-file-certificate-outline" color="white"
                  title="Terms of Service"></v-icon>
                Terms of Service
              </v-list-item>

              <v-list-item class="row-pointer" :to="{ name: 'page-policy' }">
                <v-icon class="mx-1" icon="mdi-police-badge-outline" color="white" title="Privacy & Policy"></v-icon>
                Privacy & Policy
              </v-list-item>

              <v-list-item class="row-pointer" :to="{ name: 'page-about' }">
                <v-icon class="mx-1" icon="mdi-information-variant" color="white" title="About Us"></v-icon>
                About Us
              </v-list-item>


            </v-list>
          </v-menu>
        </div>
      <!-- </v-btn> -->
      <!-- ABOUT MENU -->

      <!-- ACCOUNT MENU -->
      <!-- <v-btn icon> -->
        <div class="d-flex justify-end mr-6" v-if="isMobile">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-icon class="row-pointer" v-bind="props" color="white" icon="mdi-account-circle" title="account"></v-icon>
            </template>

            <v-list style="background-color: #0A0A0A ; width: 220px;" class="text-white">

              <!-- <v-list-item class="row-pointer" :to="{name:'page-terms'}" v-if="isLoggedIn">
                <v-icon class="mx-1" icon="mdi-account-cog" color="white" title="Profile" ></v-icon>
                Profile
              </v-list-item> -->

              <v-list-item @click="logout" class="row-pointer" v-if="isLoggedIn">
                <v-icon class="top-user-icon mx-1" color="white" icon="mdi-logout" title="Logout"></v-icon>
                Log out
              </v-list-item>

              <v-list-item v-else class="row-pointer" :to="{ name: 'page-signin' }">
                <v-icon class="top-user-icon mx-1" color="white" icon="mdi-login" title="Login"></v-icon>
                Log in
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      <!-- </v-btn> -->
      <!-- ACCOUNT MENU -->

    </v-app-bar>
    <!-- TOP APP BAR -->




    <div v-else class="d-flex justify-end">
      <v-icon v-if="!isLoggedIn" @click="go('page-signin')" class="my-2 mr-10 top-user-icon row-pointer mt-3" color="white"
        icon="mdi-account-circle" title="Account"></v-icon>
      <v-icon v-if="!isMobile && isLoggedIn" @click="logout" class="mt-5 mr-10 top-user-icon row-pointer" color="white"
        icon="mdi-logout" title="Logout"></v-icon>
    </div>

    <!-- MENU SECTION -->
    <MobileMenuSection v-if="isMobile" />
    <WebMenuSection v-else />
    <!-- MENU SECTION -->




    <!-- MAIN -->
    <router-view></router-view>
    <!-- MAIN -->


    <!-- PLAYER -->
    <MobilePlayer v-if="isMobile" />
    <WebPlayer v-else />
    <!-- PLAYER -->

    <!-- Loading dialog -->
    <v-dialog style="background-color: transparent !important;" v-model="loading" persistent width="300">
      <div class="mx-auto">
        <v-progress-circular indeterminate color="#FF0A64"></v-progress-circular>
      </div>
    </v-dialog>

    <v-snackbar v-model="getDoneEvent.status" :timeout="getDoneEvent.timeout">
      <v-icon :color="getDoneEvent.color">mdi-check-circle</v-icon>
      {{ getDoneEvent.message }}
    </v-snackbar>

  </v-app>

</template>

<script>
import { mapState } from 'vuex';
import MobileMenuSection from '@/components/mobile/MenuSection.vue';
import WebMenuSection from '@/components/web/MenuSection.vue';
import MobilePlayer from '@/components/mobile/PlayerSection.vue';
import WebPlayer from '@/components/web/PlayerSection.vue';
import AudioPlayer from '@/AudioPlayer';
import store from './store';
import { signout } from './api';

const audioPlayer = new AudioPlayer();

export default {
  name: 'App',

  components: {
    MobileMenuSection,
    WebMenuSection,
    MobilePlayer,
    WebPlayer
  },



  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.$store.commit('checkDevice')
    }); // Clean up event listener
    document.removeEventListener('contextmenu', this.disableContextMenu);

  },

  computed: {
    isMobile() {
      return this.$store.getters.getIsMobile;
    },

    isLoggedIn() {
      return store.getters.getIsLoggedIn
    },

    getDoneEvent() {
      return this.$store.getters.getDoneEvent
    },

    settings() {
      return this.$store.getters.settings;
    },

    ...mapState(['loading']),
  },


  async created() {

    this.$store.commit('checkDevice')
    window.addEventListener('resize', () => {
      this.$store.commit('checkDevice')
    }); // Update on window resize
    this.$store.commit('setPlayerElement', audioPlayer);
    document.addEventListener('contextmenu', this.disableContextMenu);
  },

  methods: {

    disableContextMenu(event) {
      event.preventDefault();
    },

    async logout() {
      //call api
      await signout()
      this.$router.push({ name: 'page-home' })
    },

    go(routeName, params = null) {
      if (params) {
        this.$router.push({ name: routeName, params: params });
      } else {
        this.$router.push({ name: routeName });
      }
    }
  }

}
</script>
