// // Styles
// import '@mdi/font/css/materialdesignicons.css'
// import 'vuetify/styles'

// // // Vuetify
// // import { createVuetify } from 'vuetify'

// // export default createVuetify(
// //   // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// //   {
// //     icons: {
// //       defaultSet: 'mdi', // This is already the default value - only for display purposes
// //     },
// //   }

// // )

// import { createVuetify } from 'vuetify';
// // import { mdi } from 'vuetify/iconsets/mdi';
// import { VApp } from 'vuetify/components/VApp';
// import { VBtn } from 'vuetify/components/VBtn';
// import { VCard } from 'vuetify/components/VCard';
// import { VIcon } from 'vuetify/components/VIcon';
// import { VMain } from 'vuetify/lib/components/index.mjs';
// import { VImg } from 'vuetify/lib/components/index.mjs';
// import { VList } from 'vuetify/components/VList';
// import { VMenu } from 'vuetify/components/VMenu';
// import { VSnackbar } from 'vuetify/components/VSnackbar';
// import { VTable } from 'vuetify/components/VTable';
// import { VDialog } from 'vuetify/lib/components/index.mjs';
// import { VDivider } from 'vuetify/components/VDivider';


// export default createVuetify({
//   components: {
//     VApp,
//     VBtn,
//     VCard,
//     VIcon,
//     VMain,
//     VImg,
//     VList,
//     VMenu,
//     VSnackbar,
//     VDialog,
//     VTable,
//     VDivider
//   },
//   // icons: {
//   //   defaultSet: 'mdi',
//   //   sets: {
//   //     mdi,
//   //   },
//   // },
// });


// plugins/vuetify.js
// import 'vuetify/styles'
import { createVuetify } from 'vuetify'

export default createVuetify({
    icons: {
        defaultSet: 'mdi',
    },
})