// AudioPlayer.js

import store from "./store";

class AudioPlayer {
  constructor() {
    this.audioElement = new Audio();
  }

  getAudio() {
    return this.audioElement
  }

  setSource(source, metadata) {

    this.audioElement.src = source;
    this.audioElement.poster = metadata.thumbnail_url

    if ("mediaSession" in navigator) {

      navigator.mediaSession.metadata = new MediaMetadata({
        title: metadata.title,
        artist: metadata.artist,
        album: metadata.album,

        artwork: [
          {
            src: metadata.thumbnail_url, // URL to your cover art image
            sizes: '300x300', // specify image dimensions
            type: 'image/jpeg', // specify image type
          }
        ]

      })
    }

  }

  play(track) {
    document.title = track.title
    this.audioElement.play();
  }

  pause() {
    this.audioElement.pause();
    document.title=store.getters.getCurrentComponent
  }

  seek(time) {
    this.audioElement.currentTime = time;
  }

  volume(value) {
    this.audioElement.volume = value
  }

  mute(value) {
    this.audioElement.muted = value

  }

  getCurrentTime() {
    return this.audioElement.currentTime;
  }
}

export default AudioPlayer;
