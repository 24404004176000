export function getCurrentUrl() {
    return window.location.href
}

export function currentUrlParser() {

    var url = getCurrentUrl()

    if (url.includes('album')) {
        return 'album';
    } else if (url.includes('playlist')) {
        return 'playlist'
    } else if (url.includes('track')) {
        return 'track'
    } else if (url.includes('artist')) {
        return 'artist'
    } else {
        return 'home'
    }
}

export function timeFormat(seconds) {
    var time = Math.round(seconds);
    var minute = Math.floor(time / 60);
    var second = time - (minute * 60);
    if (second < 10) {
        var extraZero = "0";
    } else {
        extraZero = "";
    }

    return minute + ":" + extraZero + second;
}

export function timeProgress(currentTime, duration) {

    var progress = currentTime / duration * 100;

    return progress
}

export function scrollToTop() {

    // Using modern smooth scrolling behavior
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    })
}


export function getTokenFromStorage() {
    const token = localStorage.getItem('auth'); // Use sessionStorage if appropriate

    // If token exists, return it; otherwise, return null
    return token ? token : null;
}

export function setTokenToStorage(token) {
    localStorage.setItem('auth', token); // Use sessionStorage if appropriate
}

export function deleteTokenToStorage() {
    localStorage.removeItem('auth'); // Use sessionStorage if appropriate
}

export function replaceChars(title) {
    // Replace symbols and bad characters with hyphens
    let seoFriendlyTitle = title.replace(/[^\w가-힣\s]/g, '-');

    // Replace multiple spaces with a single hyphen
    seoFriendlyTitle = seoFriendlyTitle.replace(/\s+/g, '-');

    // Remove leading and trailing hyphens
    seoFriendlyTitle = seoFriendlyTitle.replace(/^-+|-+$/g, '');

    // Convert to lowercase
    seoFriendlyTitle = seoFriendlyTitle.toLowerCase();

    return seoFriendlyTitle;
}


export function truncateString(inputString, maxLength = 200) {
    // Check if the inputString is longer than the maxLength
    if (inputString.length > maxLength) {
        // Keep only the first maxLength characters and add "..." at the end
        return inputString.slice(0, maxLength) + " ...";
    } else {
        // If the inputString is already shorter than or equal to maxLength, return it as is
        return inputString;
    }
}

export function shuffleArray(array) {
    const shuffledArray = [...array]; // Create a copy of the original array

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        // Swap shuffledArray[i] and shuffledArray[j]
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray; // Return the shuffled array
}


export function albumtitle(albumTitle) {
    // Convert the albumTitle to lower case for case-insensitive comparison
    const lowerCaseTitle = albumTitle.toLowerCase();

    // List of keywords to check
    const keywords = [
        "ost",
        "soundtrack",
        "sound track",
        "osts",     // Added plural form to cover variations
        "soundtracks" // Added plural form for consistency
    ];

    

    // Check if the title contains any of the keywords
    const containsKeyword = keywords.some(keyword => lowerCaseTitle.includes(keyword));

    // Return the original title if any keyword is found; otherwise, append " OST"
    return containsKeyword ? albumTitle : albumTitle + ' OST';
}
