<template>

    <v-bottom-navigation grow bg-color="black" height="55">

      <v-btn value="nearby" :to="{name:'page-home'}">
        <v-icon>mdi-home</v-icon>
        Home
      </v-btn>

      <v-btn :to="{name:'page-search'}" value="search">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>

      <v-btn value="explore" :to="{name:'page-explore'}">
        <v-icon>mdi-play-box-multiple</v-icon>
        Explore
      </v-btn>

      <v-btn value="favorites" :to="{name:'page-library'}">
        <v-icon>mdi-bookmark</v-icon>

        Yours
      </v-btn>

      <!-- <v-btn value="profile">
        <v-icon>mdi-account</v-icon>

        Profile
      </v-btn> -->

      <!-- <v-btn value="logout">
        <v-icon>mdi-logout</v-icon>
        Logout
      </v-btn> -->

    </v-bottom-navigation>



</template>

<script>


  export default {
    data() {
      return {
        selected: '',
        items: [
          { icon: 'mdi-home', text: 'Home', route: '/' },
          { icon: 'mdi-search', text: 'Search', route: '/search' },
          { icon: 'mdi-library-music', text: 'Library', route: '/library' },
          { icon: 'mdi-radio', text: 'Radio', route: '/radio' },
        ],
      };
    },
    computed: {
      count() {
        return this.$store.getters.getCount;
      }
    },

    methods: {
      setCurrentTrack(track) {
        this.$store.commit('setCurrentTrack',track);
      },

      setCurrentTrackAsync(track) {
        this.$store.dispatch('setCurrentTrackAsync',track);
      }
    }
  };

</script>

<style>

</style>


