import axios from 'axios';
import store from './store';

import {
  getTokenFromStorage,
  setTokenToStorage,
  deleteTokenToStorage

} from './helpers';

export const baseURL = store.getters.getApiBaseUrl // Your API base URL


const axiosInstance = axios.create({
  baseURL: baseURL
})

// Define your API request functions here
export const getRandomTracks = async () => {


  try {
    const response = (await axiosInstance.get(`/api/tracks?random=true&limit=20`));
    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    // console.log(error)
    return []
  }
}

export const getNewAlbums = async (limit = 6) => {

  store.dispatch('startLoading');


  try {
    // await new Promise(resolve => setTimeout(resolve, 500));
    const response = await axiosInstance.get(`/api/albums?order_by=updated_at&order_type=desc&limit=${limit}`);

    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    // console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const getExplore = async (next = 1, sortby = 'release_date') => {

  store.dispatch('startLoading');

  try {

    // if(next===1){
    //   await new Promise(resolve => setTimeout(resolve, 500));
    // }
    const response = await axiosInstance.get(`/api/albums?order_by=` + sortby + `&limit=54&order_type=desc&page=` + next);

    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    // console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const getAlbum = async (id) => {

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.get(`/api/albums/` + id);
    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    // console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const getDramaInfo = async (id) => {

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.get(`/api/info/` + id);
    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    // console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const getTrack = async (id) => {

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.get(`/api/tracks/` + id);
    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const getPlaylist = async (id) => {

  store.dispatch('startLoading');


  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.get(`/api/playlists/` + id);
    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const getArtist = async (id) => {

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.get(`/api/artists/` + id);
    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const search = async (term) => {

  store.dispatch('startLoading')

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.get(`/api/search?term=` + term)
    store.dispatch('stopLoading')

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    // console.log(error)
    store.dispatch('stopLoading')
    return []
  }
}

export const getMe = async () => {

  var token = getTokenFromStorage()

  if (!token) {
    return false
  }

  // store.dispatch('startLoading');
  const headers = {
    "Authorization": "Bearer " + token,
  }

  try {

    const response = await axiosInstance.get(`/api/auth/me`, { headers: headers });
    if (response && response.status == 200) {
      return response.data.data
    } else {
      return false
    }

  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    return []

  }
};

export const signup = async (email, password, confirmPassword) => {

  store.dispatch('startLoading')

  // store.dispatch('startLoading');


  const params = {
    'email': email,
    'password': password,
    'confirm_password': confirmPassword
  }

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.post(`/api/auth/signup`, params);

    if (response && response.status == 200 && response.data.ok) {
      setTokenToStorage(response.data.data.access_token)
      store.dispatch('setCurrentUserUserAsync', response.data.data.user)
      store.dispatch('setLoggedinAsync', true)
      store.dispatch('stopLoading')
      return response.data
    } else {
      store.dispatch('stopLoading')
      return response.data
    }
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading')
    return false
  }
}

export const signin = async (email, password) => {

  const params = {
    'email': email,
    'password': password
  }

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.post(`/api/auth/signin`, params);
    store.dispatch('stopLoading');
    if (response && response.status == 200 && response.data.ok) {
      setTokenToStorage(response.data.data.access_token)
      store.dispatch('setCurrentUserUserAsync', response.data.data.user)
      store.dispatch('setCurrentUserPlylistsAsync', response.data.data.playlists)
      store.dispatch('setLoggedinAsync', true)
      return response.data
    } else {
      return response.data
    }

  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return false

  }
};

export const googleJoin = async (token) => {

  // store.dispatch('startLoading');


  const params = {
    'token': token
  }

  try {

    const response = await axiosInstance.post(`/api/auth/googleJoin`, params);

    if (response && response.status == 200 && response.data.ok) {
      setTokenToStorage(response.data.data.access_token)
      store.dispatch('setCurrentUserUserAsync', response.data.data.user)
      store.dispatch('setCurrentUserPlylistsAsync', response.data.data.playlists)
      store.dispatch('setLoggedinAsync', true)
      return response.data
    } else {
      return response.data
    }

  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    return false

  }
};

export const signout = async () => {

  var token = getTokenFromStorage()

  if (!token) {
    return false
  }



  store.dispatch('startLoading');


  // store.dispatch('startLoading');
  const headers = {
    "Authorization": "Bearer " + token,
  }

  try {

    const response = await axiosInstance.post(`/api/auth/signout`, {}, { headers: headers });


    if (response && response.status == 200 && response.data.ok) {
      deleteTokenToStorage()
      store.dispatch('setCurrentUserUserAsync', null)
      store.dispatch('setCurrentUserPlylistsAsync', null)
      store.dispatch('setLoggedinAsync', false)
      store.dispatch('stopLoading')
      return response.data
    } else {
      store.dispatch('stopLoading')
      return false
    }

  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    store.dispatch('stopLoading')
    //console.log(error)
    return []

  }
};

export const createPlaylist = async (title, thumbnail = null, tracks = null) => {

  var token = getTokenFromStorage()

  if (!token) {
    return false
  }

  // store.dispatch('startLoading');
  const headers = {
    "Authorization": "Bearer " + token,
  }

  const params = {
    title: title,
    thumbnail: thumbnail,
    tracks: tracks
  }



  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.post(`/api/playlists/create`, params, { headers: headers });
    store.dispatch('stopLoading');

    store.dispatch('setCurrentUserPlylistsAsync', response.data.data.all)

    //set event
    store.commit('setDoneEvent', { 'status': true, 'message': 'Your playlist created successfully' })

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    //set event
    store.commit('setDoneEvent', { 'status': true, 'message': 'Your playlist did not create' })
    return []
  }
}

export const addTrackToPlaylist = async (playlist_hash_id, track_hash_id) => {

  var token = getTokenFromStorage()

  if (!token) {
    return false
  }


  // store.dispatch('startLoading');
  const headers = {
    "Authorization": "Bearer " + token
  }


  const params = {
    playlist_id: playlist_hash_id,
    track_id: track_hash_id
  }



  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.post(`/api/playlists/addTrack`, params, { headers: headers });
    store.dispatch('stopLoading');

    //set event
    store.commit('setDoneEvent', { 'status': true, 'message': 'The track added to your playlist' })

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const deleteTrackFromPlaylist = async (playlist_hash_id, track_hash_id) => {

  var token = getTokenFromStorage()

  if (!token) {
    return false
  }


  // store.dispatch('startLoading');
  const headers = {
    "Authorization": "Bearer " + token,
  }

  const params = {
    playlist_id: playlist_hash_id,
    track_id: track_hash_id
  }



  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.delete(`/api/playlists/deleteTrack`, { params: params, headers: headers });
    store.dispatch('stopLoading');

    //set event
    store.commit('setDoneEvent', { 'status': true, 'message': 'The track deleted from playlist' })

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}

export const deleteMyPlaylist = async (playlist_hash_id) => {

  var token = getTokenFromStorage()

  if (!token) {
    return false
  }


  // store.dispatch('startLoading');
  const headers = {
    "Authorization": "Bearer " + token,
  }

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.delete(`/api/playlists/` + playlist_hash_id, { headers: headers });
    store.dispatch('stopLoading');

    //set event
    store.commit('setDoneEvent', { 'status': true, 'message': 'Your playlist deleted successfully' })

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}


export const resetPassword = async (email, recaptcha_token) => {

  const params = {
    'email': email,
    'g_response': recaptcha_token
  }

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.post(`/api/auth/prepare-reset-password`, params);
    store.dispatch('stopLoading');
    if (response && response.status == 200 && response.data.ok) {
      return response.data
    } else {
      return response.data
    }

  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return false

  }
};

export const doResetPassword = async (key, password, confirm_password) => {

  const params = {
    'key': key,
    'password': password,
    'confirm_password': confirm_password
  }

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.post(`/api/auth/do-reset-password`, params);
    store.dispatch('stopLoading');
    if (response && response.status == 200 && response.data.ok) {
      return response.data
    } else {
      return response.data
    }

  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    //console.log(error)
    store.dispatch('stopLoading');
    return false

  }
};


export const getCast = async (id) => {

  store.dispatch('startLoading');

  try {

    // await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axiosInstance.get(`/api/casts/` + id);
    store.dispatch('stopLoading');

    return response.data;
  } catch (error) {
    // throw new Error('Failed to fetch tracks.');
    // console.log(error)
    store.dispatch('stopLoading');
    return []
  }
}


export const addEvent = async (action) => {


  try {

    const response = await axiosInstance.post(`/api/event`, { action: action });

    return response.data;
  } catch (error) {

    return []
  }
}


export const getHomeCollections = async () => {

  store.dispatch('startLoading');

  try {

    const response = await axiosInstance.get(`/api/collections`);
    store.dispatch('stopLoading');
    return response.data;
  } catch (error) {
    store.dispatch('stopLoading');
    return []
  }
}

export const getCollection = async (uslug) => {

  store.dispatch('startLoading');

  try {

    const response = await axiosInstance.get(`/api/collections/${uslug}`);
    store.dispatch('stopLoading');
    return response.data;
  } catch (error) {
    store.dispatch('stopLoading');
    return []
  }
}


export const getAlbumRelatd = async (albumId) => {

  try {
    const response = await axiosInstance.get(`/api/albums/relateds/${albumId}`);
    return response.data;
  } catch (error) {
    return []
  }
}


export const getArtistRelatd = async (artistId) => {

  try {
    const response = await axiosInstance.get(`/api/artists/relateds/${artistId}`);
    return response.data;
  } catch (error) {
    return []
  }
}

export const getTrackRelatd = async (trackId) => {

  try {
    const response = await axiosInstance.get(`/api/tracks/relateds/${trackId}`);
    return response.data;
  } catch (error) {
    return []
  }
}

