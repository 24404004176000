import { createStore } from 'vuex'




// Define your state, mutations, actions, and getters here
const store=createStore({

    state: {
        // apiBaseUrl:'http://192.168.1.5:8000',
        // streamerUrl:'https://cdn.ostfly.com/stream.php?fid=',
        // websiteDomain:'http://192.168.1.5:8080',
        apiBaseUrl:'https://api.ostfly.com',
        streamerUrl:'https://cdn.ostfly.com/stream.php?fid=',
        websiteDomain:'https://app.ostfly.com',
        logoUrl:'https://cdn.ostfly.com/images/OSTFly_final.297ae3c3.webp',
        googleAuthClientId:'1058781933875-pfdv38te6s2mo4iqbqsdibtaaldd5jlm.apps.googleusercontent.com',
        googleRecaptcha3ClientId:'6LeO6p0pAAAAABGgKjOHWsW1iZF3tUDQZ590AQjR',
        currentTrack: null,
        isMobile:false,
        currentTracks:null,
        currentPageUrl:null,
        currentAlbum:null,
        currentPlaylist:null,
        currentArtist:null,
        currentUser:{
            loggedin:false,
            user:null,
            playlists:[]

        },
        doneEvent:{
            status:false,
            message:null,
            color:'#FFADCB',
            timeout:2500
        },
        currentIndex:0,
        homeNewOsts:null,
        loading: false,
        player:{
            audioClass:null,
            currentTime:0,
            currentValume:100,
            isPlaying:false,
            duration:0,
            src:null,
            muted:false,
            visible:true
        },
        isAuthPage:false,
        isTyping:false,
        explore:null,
        settings:{
            telegram_chanel_url:"https://t.me/ostfly"
        },
        scroll:{
            lastkey:null,
            scrollPosition:0
        },
        currentComponent:null,
        currentTitle:null,
        currentItem:null
    },

    mutations: {

        setScroll(state, obj) {
            state.scroll = obj;
        },

        setLoading(state, status) {
            state.loading = status;
        },

        setExplore(state, explore) {
            state.explore = explore;
        },

        setIsTyping(state,value){
            state.isTyping=value
        },

        setCurrentTrack(state,track){
            state.currentTrack=track
        },

        setCurrentTracks(state,tracks){
            state.currentTracks=tracks
        },

        checkDevice(state) {
            state.isMobile = window.innerWidth <= 920; // Update isMobile based on breakpoint 768
        },

        setCurrentPageUrl(state,currentPageUrl){
            state.currentPageUrl=currentPageUrl
        },

        setCurrentAlbum(state,album){
            state.currentAlbum=album
        },

        setCurrentPlaylist(state,playlist){
            state.currentPlaylist=playlist
        },

        setCurrentArtist(state,artist){
            state.currentArtist=artist
        },

        setCurrentUser(state,user){
            state.currentUser=user
        },

        setCurrentItem(state,item){
            state.currentItem=item
        },

        //----------------PLAYER-----------------
        setPlayerElement(state,audioClass){
            state.player.audioClass=audioClass
        },

        setPlayerCurrentTime(state,time){
            state.player.currentTime=time
            state.player.audioClass.seek(time)
        },

        setPlayerCurrentValume(state,value){
            state.player.currentValume=value
            state.player.audioClass.valume(value)
        },

        setPlayerPlay(state){
            state.player.isPlaying=true,
            state.player.audioClass.play(state.currentTrack)
        },

        setPlayerPause(state){
            state.player.isPlaying=false,
            state.player.audioClass.pause()
        },

        setPlayerSrc(state,src){
            state.player.audioClass.setSource(src)
        },

        setDoneEvent(state,evObj){
            state.doneEvent=evObj
        },

        setCurrentComponent(state,component){
            state.currentComponent=component
        },

        setTrack(state,payload){

            var trackObj=payload.trackObj
            var play=payload.play
            var listObj=payload.listObj



            state.currentTrack=trackObj
            state.currentTracks=listObj


            state.currentIndex = state.currentTracks.indexOf(trackObj)
            state.player.isPlaying=play

            var src=state.streamerUrl + trackObj.hash_id
            state.player.src=src
           
            state.player.audioClass.setSource(src,{
                "title":trackObj.title+' | OSTFly',
                "artist":trackObj.artists[0].name,
                "album":trackObj.albums[0].title,
                "thumbnail_url": trackObj.thumbnail.link
            })
                


            if(play==true){
                state.player.audioClass.play(state.currentTrack)
            }else{
                state.player.audioClass.pause()
            }

        },

        updateCurrentTime(state,currentTime){
            state.player.currentTime=currentTime
        },

        seekTime(state,time){
            state.player.audioClass.seek(time)
            state.player.currentTime=time
        },

        updateCurrentIndex(state,index){
            state.currentIndex=index
        },

        updateVolume(state,value){
            state.player.audioClass.volume(value)
            state.player.currentValume=value
        },

        updateMuted(state,value){
            state.player.muted=value
            state.player.audioClass.mute(value)
        },

        setHomeNewOsts(state,albums){
            state.homeNewOsts=albums
        },

        setLoggedIn(state,loggedin){
            state.currentUser.loggedin=loggedin
        },

        setCurrentUserUser(state,user){
            state.currentUser.user=user
        },

        setCurrentUserPlylists(state,playlists){
            state.currentUser.playlists=playlists
        }
    },

    actions: {

        setUpdateCurrentIndex({commit},value){
            commit('updateCurrentIndex',value)
        },

        setIsTypingAsync({commit},value){
            commit('setIsTyping',value)
        },

        startLoading({ commit }) {
            commit('setLoading', true);
        },

        stopLoading({ commit }) {
            commit('setLoading', false);
        },

        setCurrentTrackAsync({ commit },track) {
            commit('setCurrentTrack',track);
        },

        setCurrentTracksAsync({ commit },tracks){
            commit('setCurrentTracks',tracks);
        },

        setCurrentPageUrlAsync({ commit },currentPageUrl){
            commit('setCurrentPageUrl',currentPageUrl);
        },

        setCurrentAlbumAsync({ commit },album){
            commit('setCurrentAlbum',album);
        },

        setCurrentPlaylistAsync({ commit },playlist){
            commit('setCurrentPlaylist',playlist);
        },

        setCurrentPlaylistsAsync({ commit },playlist){
            commit('setCurrentPlaylists',playlist);
        },

        setCurrentArtistAsync({ commit },artist){
            commit('setCurrentArtist',artist);
        },

        setCurrentUserAsync({ commit },user){
            commit('setCurrentUser',user);
        },

        setTrackUpdate(state,payload){

            state.commit('setTrack',payload)
        },

        setUpdateHomeNewOsts(state,albums){
            state.commit('setHomeNewOsts',albums)
        },

        setLoggedinAsync(state,loggedin){
            state.commit('setLoggedIn',loggedin)
        },

        setCurrentUserUserAsync(state,user){
            state.commit('setCurrentUserUser',user)
        },

        setCurrentUserPlylistsAsync(state,playlists){
            state.commit('setCurrentUserPlylists',playlists)
        },

        setCurrentItemAsync(state,item){
            state.commit('setCurrentItem',item)
        }

    },

    getters: {

        getCurrentComponent(state){
            return state.currentComponent
        },

        getScroll(state){
            return state.scroll
        },

        getWebsiteDomain(state){
            return state.websiteDomain
        },

        getApiBaseUrl(state){
            return state.apiBaseUrl
        },

        getCurrentTrack(state) {
            return state.currentTrack
        },

        getCurrentTracks(state) {
            return state.currentTracks
        },

        getIsMobile(state){
            return state.isMobile
        },

        getCurrentPageUrl(state){
            return state.currentPageUrl
        },

        getCurrentAlbum(state){
            return state.currentAlbum
        },

        getCurrentPlaylist(state){
            return state.currentPlaylist
        },


        getCurrentArtist(state){
            return state.currentArtist
        },

        getCurrentUser(state){
            return state.currentUser
        },

        getPlayerProps(state){
            return state.player
        },

        getCurrentIndex(state){
            return state.currentIndex
        },

        getHomeNewOsts(state){
            return state.homeNewOsts
        },

        getIsLoggedIn(state){
            return state.currentUser.loggedin
        },

        getIsTyping(state){
            return state.isTyping
        },

        getGoogleAuthClientId(state){
            return state.googleAuthClientId
        },

        getGoogleRecaptcha3ClientId(state){
            return state.googleRecaptcha3ClientId
        },

        getLogoUrl(state){
            return state.logoUrl
        },

        getDoneEvent(state){
            return state.doneEvent
        },

        getExplore(state){
            return state.explore
        },

        getGen(){
            return ''
        },

        settings(state){
            return state.settings
        },

        getCurrentItem(state){
            return state.currentItem
        }

    }

});


export default store
