import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import {getMe} from './api';

// Lazy load components
const HomeSection = () => import('@/components/HomeSection.vue');
const ExploreSection = () => import('@/components/ExploreSection.vue');
const AlbumSection = () => import('@/components/AlbumSection.vue');
const DramaInfoSection = () => import('@/components/DramaInfoSection.vue');
const CastSection = () => import('@/components/CastSection.vue');
const PlaylistSection = () => import('@/components/PlaylistSection.vue');
const TrackSection = () => import('@/components/TrackSection.vue');
const ArtistSection = () => import('@/components/ArtistSection.vue');
const SearchSection = () => import('@/components/SearchSection.vue');
const LibrarySection = () => import('@/components/LibrarySection.vue');
const SigninSection = () => import('@/components/SigninSection.vue');
const ResetPasswordSection = () => import('@/components/ResetPasswordSection.vue');
const DoResetPasswordSection = () => import('@/components/DoResetPasswordSection.vue');
const SignupSection = () => import('@/components/SignupSection.vue');
const AboutUs = () => import('@/components/AboutUs.vue');
const TermsService = () => import('@/components/TermsService.vue');
const PrivacyPolicy = () => import('@/components/PrivacyPolicy.vue');
const CollectionPage = () => import('@/components/CollectionPage.vue');


// Init auth handler
let access = null;


const routes = [
  { path: '/', component: HomeSection, name: 'page-home', meta: { title: 'Stream K-Drama OSTs | Listen to Korean Drama Soundtracks | OSTFly' } },
  { path: '/explore', component: ExploreSection, name: 'page-explore', meta: { title: 'Explore Korean Music: Discover Categories of K-Drama OSTs and More' }  },
  { path: '/collection/:uslug', component: CollectionPage, name: 'page-collection' },
  { path: '/album/:id/:slug?', component: AlbumSection, name: 'page-album' },
  { path: '/drama-info/:id/:slug?', component: DramaInfoSection, name: 'page-drama-info' },
  { path: '/cast/:id/:slug?', component: CastSection, name: 'page-cast' },
  { path: '/playlist/:id/:slug?', component: PlaylistSection, name: 'page-playlist' },
  { path: '/track/:id/:slug?', component: TrackSection, name: 'page-track' },
  { path: '/artist/:id/:slug?', component: ArtistSection, name: 'page-artist' },
  { path: '/search', component: SearchSection, name: 'page-search',  meta: { title: 'Search K-Drama OSTs, Albums, Cast & Artists on OSTFly' }  },
  { 
    path: '/library', 
    component: LibrarySection, 
    name: 'page-library',
    meta: { requiresAuth: true, title: 'Your Library: Manage Your Playlists'  },

  },
  { path: '/signin', component: SigninSection, name: 'page-signin',  meta: { title: 'Login to OSTFly: Your K-Drama OST Streaming Platform' } },
  { path: '/signup', component: SignupSection, name: 'page-signup',  meta: { title: 'Create Your OSTFly Account: Start Streaming K-Drama OSTs' } },
  { path: '/about', component: AboutUs, name: 'page-about', meta: { title: 'About OSTFly: Your Ultimate K-Drama Music Platform' } },
  { path: '/terms', component: TermsService, name: 'page-terms', meta: { title: 'OSTFly Terms of Service' } },
  { path: '/privacy', component: PrivacyPolicy, name: 'page-policy', meta: { title: 'OSTFly Privacy Policy' } },
  { path: '/prepare-reset-password', component: ResetPasswordSection, name: 'page-prepare-reset-password', meta: { title: 'Reset Password | OSTFly' } },
  { path: '/reset-password', component: DoResetPasswordSection, name: 'page-do-reset-password', meta: { title: 'Reset Password | OSTFly' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior(to) {
  //   if (to.name !== 'page-explore' && to.name !== 'page-home') {
  //     document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  //     window.scrollTo(0, 0);
  //   }
  // },
});

// Global navigation guard for authentication
router.beforeEach(async (to, from, next) => {

  if (!access) {
    try {
      const me = await getMe();
      if (me) {
        store.dispatch('setCurrentUserUserAsync', me);
        store.dispatch('setCurrentUserPlylistsAsync', me.playlists);
        store.dispatch('setLoggedinAsync', true);
        access = true;
      }
    } catch (error) {
      access = false;
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!access) {
      next({ name: 'page-signin' });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {

  

  if(to?.meta?.title){

    store.commit('setCurrentComponent',to.meta.title)

    if(!store.getters.getPlayerProps.isPlaying){
      document.title = to.meta.title;
    }
    
  }

  
  
});

export default router;
